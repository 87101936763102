const host = process.env.REACT_APP_BACKEND_URL_VAR;
const api = `${host}/api`;
const token = `${host}/oauth/token`;
const restaurantWidget = `${api}/restaurant-widget`;
const restaurantTable = `${api}/restaurant-table/widget`;
const restaurantSchedule = `${api}/restaurant-schedule/widget`;
const sendConfirmCode = `${api}/account/confirmation/code/request`;
const widgetBooking = `${api}/widget/booking`;

export {
  restaurantWidget,
  restaurantTable,
  restaurantSchedule,
  sendConfirmCode,
  token,
  widgetBooking,
};
