import { connect } from 'react-redux';

import ShowInitialized from './ShowInitialized';
import { IAppStore } from '../../../types/IAppStore';
import { StateProps } from './IShowInitialized';

function mapStateToProps(state: IAppStore): StateProps {
  const { restaurantInfoInitialized } = state.root;
  return {
    initialized: restaurantInfoInitialized,
  }
}

export default connect(mapStateToProps, null)(ShowInitialized);

