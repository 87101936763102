import { IAppActions } from '../types/IAppActions';
import { IReserveFormStore } from '../types/IReserveFormStore';
import * as ReserveFormActionsTypes from '../actions/reserveForm/ReserveFormActionsTypes';

export const initState: IReserveFormStore = {
  freeTables: 0,
  freeTablesError: false,
  maxPersons: 8,
  date: '',
  persons: 0,
  scheduleOptions: [],
};

export default function reducer(state = initState, action: IAppActions): IReserveFormStore {
  switch (action.type) {
    case ReserveFormActionsTypes.GET_TABLES_COUNT_REQUEST:
      return {
        ...state,
        date: action.payload.date,
        persons: action.payload.persons,
      };

    case ReserveFormActionsTypes.GET_TABLES_COUNT_SUCCESS:
      return {
        ...state,
        freeTables: action.payload.freeTables,
        freeTablesError: !action.payload.freeTables || action.payload.freeTables === 0,
      };

    case ReserveFormActionsTypes.RESTAURANT_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduleOptions: action.payload.scheduleOptions,
      };

    case ReserveFormActionsTypes.GET_MAX_PERSON_COUNT_SUCCESS:
    case ReserveFormActionsTypes.GET_MAX_PERSON_COUNT_ERROR:
      return {
        ...state,
        maxPersons: action.payload.persons,
      };

    case ReserveFormActionsTypes.GET_TABLES_COUNT_ERROR: {
      return {
        ...state,
        freeTablesError: true,
        freeTables: 0,
      }
    }

    default:
      return state;
  }
}
