import React, { useEffect, useState } from 'react';
import { Theme } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { IProps } from "./IThemeComp";
import { generateThemeOptions } from "../../../utils";

const ThemeComp: React.FC<IProps> = ({
                                       colors, children
                                     }) => {
  const [theme, setTheme] = useState<Theme | null>(null);
  useEffect(() => {
    if (colors) setTheme(createMuiTheme(generateThemeOptions(colors)))
  }, [
    colors
  ]);

  return theme ? (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  ) : null;
}

export default ThemeComp;
