import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  createStyles, Theme, withStyles,
} from '@material-ui/core/styles';
import { isSafari } from '../../helpers/helpers';

const styles = (theme: Theme) => createStyles({
  inputsCont: {
    display: 'flex',
    justifyContent: 'center',
  },
  inputField: {
    fontSize: '36px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    lineHeight: '43px',
    width: '32px',
    margin: '0 16px',
    textAlign: 'center',
    outline: 'none',
    border: 'none',
    color: theme.palette.text.primary,
    background: "none",
    borderBottom: `3px solid ${theme.palette.text.primary}`,
  },
  safariInput: {
    fontSize: '20px',
    textAlign: 'start',
    width: '32px',
  }
});

const discardNaN = (string?: string) => {
  if (typeof string !== 'string') {
    return '';
  }

  let result = '';
  for (let i = 0; i < string.length; i += 1) {
    const char = string[i];

    if ((char >= '0' && char <= '9')) {
      result = `${result}${char}`;
    }
  }
  return result;
};

class PinCode extends PureComponent<any, any> {
  value: string = '';

  textInputs: Array<any> = [];

  static propTypes = {
    fieldsCount: PropTypes.number,
    fieldMaxLength: PropTypes.number,
    numberOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onFullFilled: PropTypes.func,
  };

  static defaultProps = {
    numberOnly: false,
    onChange: () => null,
    onFullFilled: () => null,
    fieldsCount: 4,
    fieldMaxLength: 1,
  };

  constructor(props: any) {
    super(props);
    const inputs = [];

    for (let i = 0; i < props.fieldsCount; i += 1) {
      inputs[i] = '';
    }
    this.state = { inputs };
  }

  componentDidMount() {
    this.textInputs[0].focus();
  }

  handleKeyDown = (e: any) => {
    const element = e.target;
    const currentId = parseInt(element.dataset.id, 10);
    const prevElement = currentId > 0 ? this.textInputs[currentId - 1] : null;

    if (
      e.keyCode === 8
      && !element.value
      && prevElement
    ) {
      prevElement.focus();
    }
  };

  handleInput = (e: any) => {
    const { fieldMaxLength, numberOnly } = this.props;
    const { inputs } = this.state;
    const element = e.target;
    const currentId = parseInt(element.dataset.id, 10);
    let nextElement = this.textInputs[currentId + 1];
    const neWinputs = inputs.slice();
    let { value } = element;

    if (numberOnly && typeof value !== 'number') {
      value = discardNaN(value);
    }

    while (nextElement && nextElement.value && nextElement.value.length >= fieldMaxLength) {
      nextElement = this.textInputs[parseInt(nextElement.dataset.id, 10) + 1];
    }

    if (value.length === fieldMaxLength && nextElement) {
      nextElement.focus();
    }

    if (value.length > fieldMaxLength) {
      const newValue = value.substr(0, value.length - 1);
      const rest = value.substr(-1);

      value = newValue;

      if (nextElement) {
        neWinputs[nextElement.dataset.id] = `${neWinputs[nextElement.dataset.id]}${rest}`;
        nextElement.focus();
      }
    }

    neWinputs[currentId] = value;
    this.setState({ inputs: neWinputs });
    this.value = neWinputs.join('');
    this.handleChange(this.value);
  };

  handleChange = (value: string) => {
    const { onChange, onFullFilled } = this.props;

    onChange(value);

    if (this.isFullFilled()) {
      onFullFilled(value);
    }
  };

  isFullFilled = () => {
    const { fieldMaxLength, fieldsCount } = this.props;

    return String(this.value).length === fieldMaxLength * fieldsCount;
  };

  render() {
    const { fieldMaxLength, numberOnly, classes } = this.props;
    const { inputs } = this.state;
    const safariStyles = isSafari();

    return (
      <div className={classes.inputsCont}>
        {
          inputs.map((value: string, i: number) => (
            <input
              className={classNames(classes.inputField, {[classes.safariInput]: safariStyles})}
              ref={(ref) => {
                this.textInputs[i] = ref;
              }}
              data-id={i}
              type={numberOnly ? 'tel' : 'text'}
              tabIndex={i + 1}
              maxLength={fieldMaxLength}
              value={value}
              onInput={this.handleInput}
              onKeyDown={this.handleKeyDown}
              key={`code-${i}`}
            />
          ))
        }
      </div>
    );
  }
}

export default withStyles(styles)(PinCode);
