import React from 'react';

import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import Typography from '@material-ui/core/Typography';

import locales from '../../locales';

const styles = createStyles({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cancelSharpIcon: {
    fontSize: '58px',
    margin: '72px 0 12px',
  },
  msgWrap: {
    maxWidth: 420,
    textAlign: 'center',
  },
});

interface IProps extends WithStyles<typeof styles> {}

const UnknownError: React.FC<IProps> = ({ classes }) => (
  <div className={classes.wrap}>
    <CancelSharpIcon color="error" className={classes.cancelSharpIcon} />
    <div className={classes.msgWrap}>
      <Typography variant="subtitle1" color="error">
        {locales.t('common.reserveWidget.declineDesc')}
      </Typography>
    </div>
  </div>
);

export default withStyles(styles)(UnknownError);
