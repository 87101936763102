export const INIT_APP = 'INIT_APP';
export const INIT_APP_RESTAURANT_INFO = 'INIT_APP_RESTAURANT_INFO';
export const INIT_APP_WIDGET_STATE = 'INIT_APP_WIDGET_STATE';

export const SMS_CODE_VERIFY_REQUEST = 'SMS_CODE_VERIFY_REQUEST';
export const SMS_CODE_VERIFY_SUCCESS = 'SMS_CODE_VERIFY_SUCCESS';
export const SMS_CODE_VERIFY_ERROR = 'SMS_CODE_VERIFY_ERROR';

export const GUEST_BOOKING_REQUEST = 'GUEST_BOOKING_REQUEST';
export const GUEST_BOOKING_QUEUE_PROCESSING = 'GUEST_BOOKING_QUEUE_PROCESSING';
export const GUEST_BOOKING_SUCCESS = 'GUEST_BOOKING_SUCCESS';
export const GUEST_BOOKING_ERROR = 'GUEST_BOOKING_ERROR';

export const DELETE_RESERVE_REQUEST = 'DELETE_RESERVE_REQUEST';
export const DELETE_RESERVE_SUCCESS = 'DELETE_RESERVE_SUCCESS';
export const DELETE_RESERVE_ERROR = 'DELETE_RESERVE_ERROR';

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const REGISTRATION_REQUEST_SUCCESS = 'REGISTRATION_REQUEST_SUCCESS';
export const REGISTRATION_REQUEST_ERROR = 'REGISTRATION_REQUEST_ERROR';

export const RESEND_REGISTRATION_REQUEST = 'RESEND_REGISTRATION_REQUEST';
