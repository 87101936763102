import moment from 'moment';

import { getWidgetIdFromUrl } from './helpers';
import { IBookingData } from '../types/IRootWidgetStore';

const WIDGET_ACCESS_TOKEN_KEY = 'WIDGET_ACCESS_TOKEN_KEY';
const WIDGET_REFRESH_TOKEN_KEY = 'WIDGET_REFRESH_TOKEN_KEY';

type widgetData = {
  WIDGET_ACCESS_TOKEN_KEY: string;
  WIDGET_REFRESH_TOKEN_KEY: string;
  date: string;
  reserveId: number;
  userData: IBookingData
};

/**
 * @description Получить токен по ключу
 * @param tokenName
 */
function getToken(tokenName: string): string | null {
  const id = getWidgetIdFromUrl();
  const widgetData = localStorage.getItem(`${id}`);

  if (!widgetData) {
    return null;
  }

  const tokens = JSON.parse(widgetData);

  if (tokens[tokenName]) {
    const currentTime = moment();

    if (currentTime.diff(moment(tokens.date), 'h') < 24) {
      return tokens[tokenName];
    } else {
      localStorage.removeItem(`${id}`);
    }

    return null;
  }

  return null;
}

/**
 * @description Сохраняем токены в локал сторадж
 * т.к этот метод вызывается в момент авторизации/регистрации юзера то перетераем старрые данные
 * @param accessToken
 * @param refreshToken
 */
function setTokens(
  accessToken: string,
  refreshToken: string,
) {
  const id = getWidgetIdFromUrl();

  const data = {
    [WIDGET_ACCESS_TOKEN_KEY]: accessToken,
    [WIDGET_REFRESH_TOKEN_KEY]: refreshToken,
    date: moment().format(),
  };

  localStorage.setItem(`${id}`, JSON.stringify(data));
}


/**
 * @description Обновление токена
 * @param accessToken
 * @param refreshToken
 */
function updateToken(accessToken: string, refreshToken: string) {
  const id = getWidgetIdFromUrl();
  const oldWidgetData = localStorage.getItem(`${id}`);

  if (oldWidgetData) {
    const parseOldWidgetData = JSON.parse(oldWidgetData);

    const newData = {
      ...parseOldWidgetData,
      [WIDGET_ACCESS_TOKEN_KEY]: accessToken,
      [WIDGET_REFRESH_TOKEN_KEY]: refreshToken,
    };

    localStorage.setItem(`${id}`, JSON.stringify(newData))
  }
}

/**
 * @description Сохраняем данные о брони
 * @param reserveId
 * @param userData
 */
function setWidgetData(
  reserveId: number,
  userData: any,
) {
  const id = getWidgetIdFromUrl();
  const oldWidgetData = localStorage.getItem(`${id}`);

  if (oldWidgetData) {
    const newData: widgetData = {
      ...JSON.parse(oldWidgetData),
      reserveId,
      userData,
    };

    localStorage.setItem(`${id}`, JSON.stringify(newData));
  }
}

/** @description Запрашиваем данные виджета */
function getWidgetData() {
  const id = getWidgetIdFromUrl();
  const widgetData = localStorage.getItem(`${id}`);

  if (widgetData) {
    const parseWidgetData: widgetData = JSON.parse(widgetData);
    const currentTime = moment();

    if (currentTime.diff(moment(parseWidgetData.date), 'h') < 24) {
      return {
        reserveId: parseWidgetData.reserveId,
        userData: parseWidgetData.userData,
      };
    } else {
      localStorage.removeItem(`${id}`);
      return null;
    }
  }

  return null;
}

/** @description Удаляем данные о видежте */
function clearWidgetData() {
  const id = getWidgetIdFromUrl();

  localStorage.removeItem(`${id}`)
}

/** @description Получить access токен авторизации */
const getAccessToken = () => getToken(WIDGET_ACCESS_TOKEN_KEY);

/** @description Получить refresh токен авторизации */
const getRefreshToken = () => getToken(WIDGET_REFRESH_TOKEN_KEY);


export {
  clearWidgetData,
  setWidgetData,
  getAccessToken,
  getRefreshToken,
  getWidgetData,
  updateToken,
  setTokens,
};
