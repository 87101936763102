import { connect } from 'react-redux';

import ReserveSuccessCont from "./ReserveSuccessCont"
import { IAppStore } from '../../types/IAppStore';
import { StateProps } from './IReserveSuccess';

function mapStateToProps(state: IAppStore): StateProps {
  const {
    restaurantInfo,
    bookingReqData,
  } = state.root;

  return {
    restaurantInfo,
    bookingReqData,
  };
}

export default connect(mapStateToProps, {})(ReserveSuccessCont);
