import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import {
  darken,
  fade,
} from "@material-ui/core/styles/colorManipulator";
import { TWidgetColors } from "./types/IRootWidgetStore";
import { fallbackColors } from "./helpers/constants";

/**
 * @description generates ThemeOptions, defaults are set if colors is missing values
 */
export function generateThemeOptions(colors?: TWidgetColors | null): ThemeOptions {
  const fontFamilyMedium = 'GolosTextWebMedium';

  const themeColors: Required<TWidgetColors> = Object.assign({}, fallbackColors, colors);

  const primary = themeColors.mainColor;
  const primaryHover = darken(primary, 0.1503);
  const primarySelected = fade(primary, 0.3);
  const primaryPostHover = fade(primary, 0.05);

  return {
    typography: {
      h4: {
        fontFamily: fontFamilyMedium,
        fontSize: 29,
        lineHeight: '32pt',
      },
      h5: {
        fontFamily: fontFamilyMedium,
        fontSize: 24,
        lineHeight: '28pt',
      },
      h6: {
        fontFamily: fontFamilyMedium,
        fontSize: 20,
        lineHeight: '24pt',
      },
      subtitle1: {
        fontSize: 16,
        lineHeight: '16pt',
      },
      subtitle2: {
        fontFamily: fontFamilyMedium,
        fontSize: 16,
        lineHeight: '16pt',
      },
      body1: {
        fontSize: 14,
        lineHeight: '1.4',
      },
      caption: {
        color: themeColors.formBaseColor,
        fontSize: 12,
        lineHeight: '14pt',
      },
      button: {
        textTransform: 'none',
      },
      fontFamily: '"GolosTextWebRegular", "Roboto", "Arial"',
    },
    palette: {
      primary: {
        main: primary,
        contrastText: themeColors.formHeaderColor,
      },
      secondary: {
        main: '#c0c0c0',
        contrastText: '#fff',
      },
      text: {
        primary: themeColors.formBaseColor,
        hint: themeColors.formFieldColor,
      },
      /**
       * @deprecated
       grey: {
        main: '#898989',
      },
       buttonGrey: {
        main: '#79919d',
        hover: '#667a86',
        contrastText: '#fff',
      },
       */
    },
    overrides: {
      MuiInput: {
        /**
         * @description reset values to form specific
         */
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0px 1000px ${themeColors.formBackground} inset`,
            '-webkit-text-fill-color': `${themeColors.formBaseColor}`,
          },
        },
        underline: {
          '&:hover:not($disabled):not($focused):not($error):before': {borderBottomColor: primary},
        },
      },
      MuiFormLabel: {
        root: {
          color: themeColors.formFieldColor
        }
      },
      MuiDialog: {
        root: {
          backgroundColor: themeColors.pageBackground
        }
      },
      MuiBackdrop: {
        root: {
          backgroundColor: themeColors.pageBackground
        }
      },
      MuiDialogContent: {
        root: {
          backgroundColor: themeColors.formBackground
        }
      },
      MuiTooltip: {
        tooltip: {
          fontSize: 12,
          maxWidth: 360,
          padding: '16px 15px 17px 24px',
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: themeColors.formBackground,
        }
      },
      // @ts-ignore
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: themeColors.formFieldColor,
        }
      },
      // @ts-ignore
      MuiPickersCalendarHeader: {
        iconButton: {
          backgroundColor: themeColors.formBackground,
        }
      },
      MuiMenu: {
        paper: {
          backgroundColor: themeColors.formBackground,
        }
      },
      MuiMenuItem: {
        root: {
          color: themeColors.formBaseColor,
          '&$selected': {
            backgroundColor: primarySelected,
            fontWeight: 500,
            '&:focus': {
              backgroundColor: primarySelected,
            },
            '&:hover': {
              backgroundColor: primaryHover,
            },
          },
          '&:hover': {
            backgroundColor: primaryHover,
          },
          '&:focus': {
            backgroundColor: primaryPostHover,
          },
        },
      },
    },
  }
}
