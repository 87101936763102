import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import reducer from '../reducers';
import history from './history';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = typeof window === 'object'
// @ts-ignore
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  // @ts-ignore
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  }) : compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const enhancer = composeEnhancers(
  applyMiddleware(
    sagaMiddleware,
    routerMiddleware(history),
  ),
  sentryReduxEnhancer,
);

const store = createStore(
  reducer(history),
  enhancer,
);

sagaMiddleware.run(rootSaga);

export default store;
