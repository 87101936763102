import React, { Component } from 'react';

import DialogContent from '@material-ui/core/DialogContent';

import { IProps } from './IErrorCont';
import { errorType } from '../../config/constants';
import UnknownError from '../../components/Errors/UnknownError';
import ActiveReserveError from '../../components/Errors/ActiveReserveError';

class ErrorCont extends Component<IProps> {
  render() {
    const { bookingError, restaurantInfo } = this.props;
    let errorBody = <UnknownError />;

    if (bookingError) {
      switch (bookingError.type) {
        case errorType.activeReserve: {
          errorBody = (
            <ActiveReserveError
              restaurantInfo={restaurantInfo}
              bookingData={bookingError.bookingData}
            />
          );
          break;
        }
        default: {
          errorBody = (
            <UnknownError />
          );
          break;
        }
      }
    }

    return (
      <DialogContent>
        { errorBody }
      </DialogContent>
    );
  }
}

export default ErrorCont;
