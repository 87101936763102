import {
  put, all, takeLatest, select
} from 'redux-saga/effects';

import * as Sentry from '@sentry/react';

import {
  getTablesCountReq,
  getRestaurantScheduleReq,
  getMaxPersonCountReq,
} from '../api';
import * as ReserveFormActionsTypes from '../actions/reserveForm/ReserveFormActionsTypes';
import * as ReserveFormActions from '../actions/reserveForm/ReserveFormActions';
import * as IReserveFormActions from '../actions/reserveForm/IReserveFormActions';
import { getWidgetMergedSchedule, getWidgetTimeList } from '../helpers/dateHelpers';
import { IAppStore } from '../types/IAppStore';

/** @description Получение списка всех доступных столов у ресторана подходящих под параметры фильтра. */
function* getFreeTablesSaga(action: IReserveFormActions.IGetFreeTables) {
  try {
    const widgetId = yield select((state: IAppStore) => state.root.widgetId);
    const { date, persons } = action.payload;
    const count = yield getTablesCountReq(
      widgetId, date, persons,
    );

    yield put(ReserveFormActions.getFreeTablesSuccess(count));
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveFormActions.getFreeTablesError());
  }
}

/** @description Получение расписания ресторана */
function* getRestaurantSchedule(action: IReserveFormActions.IRestaurantScheduleRequest) {
  try {
    const widgetId = yield select((state: IAppStore) => state.root.widgetId);
    const { date } = action.payload;
    const scheduleData = yield getRestaurantScheduleReq(widgetId, date);

    const onlyDefaultScheduleList = scheduleData.filter((schedule: any) => !schedule.date);
    const weekScheduleCollection: any = getWidgetMergedSchedule(onlyDefaultScheduleList);
    const scheduleOptions = getWidgetTimeList(date, weekScheduleCollection);

    yield put(ReserveFormActions.getRestaurantScheduleSuccess(scheduleOptions));
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveFormActions.getRestaurantScheduleSuccess([]));
  }
}

/** @description Получение максимального числа */
function* getMaxPersonsCountSaga(action: IReserveFormActions.IGetMaxPersonCountReq) {
  try {
    const maxPersons = yield getMaxPersonCountReq(action.payload.widgetId);
    yield put(ReserveFormActions.getMaxPersonCountSuccess(maxPersons));
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveFormActions.getMaxPersonCountError(8));
  }
}

export default function* saga() {
  yield all([
    takeLatest(ReserveFormActionsTypes.GET_TABLES_COUNT_REQUEST, getFreeTablesSaga),
    takeLatest(ReserveFormActionsTypes.RESTAURANT_SCHEDULE_REQUEST, getRestaurantSchedule),
    takeLatest(ReserveFormActionsTypes.GET_MAX_PERSON_COUNT_REQUEST, getMaxPersonsCountSaga),
  ]);
}
