import * as ActionTypes from './RootWidgetActionsTypes';
import { IRootWidgetActions } from './IRootWidgetActions';
import {
  TRestaurant,
  IBookingData,
  IBookingErrorType,
  TErrorBookingData
} from '../../types/IRootWidgetStore';

/** @description Экшен для запроса инфы о ресторане при ините виджета */
export function getRestaurantInfo(widgetId: number): IRootWidgetActions {
  return {
    type: ActionTypes.INIT_APP,
    payload: {
      widgetId,
    },
  };
}

/** @description Экшен для успешной обработки запроса инфы о ресторане при ините виджета */
export function initAppRestaurantInfo(restaurantInfo: TRestaurant): IRootWidgetActions {
  return {
    type: ActionTypes.INIT_APP_RESTAURANT_INFO,
    payload: {
      restaurantInfo,
    },
  };
}

/** @description Экшен для успешной обработки запроса инфы о ресторане при ините виджета */
export function initAppWidgetState(reserveInfo: IBookingData, state?: string): IRootWidgetActions {
  return {
    type: ActionTypes.INIT_APP_WIDGET_STATE,
    payload: {
      reserveInfo,
      state,
    },
  };
}

/** @description Экшен для запроса кода регистрации */
export function sendRegistrationRequest(bookingData: IBookingData): IRootWidgetActions {
  return {
    type: ActionTypes.REGISTRATION_REQUEST,
    payload: {
      bookingData,
    },
  };
}

/** @description Экшен для обработки запроса кода регистрации */
export function sendRegistrationRequestSuccess(timeLeft: number): IRootWidgetActions {
  return {
    type: ActionTypes.REGISTRATION_REQUEST_SUCCESS,
    payload: {
      timeLeft,
    },
  };
}

/** @description Экшен для запроса кода регистрации */
export function sendRegistrationRequestError(): IRootWidgetActions {
  return {
    type: ActionTypes.REGISTRATION_REQUEST_ERROR,
  };
}

/** @description Экшен для повторного запроса кода регистрации */
export function resendRegistrationRequest(): IRootWidgetActions {
  return {
    type: ActionTypes.RESEND_REGISTRATION_REQUEST,
  };
}

/** @description Экшен для отправки запроса на проверку смс */
export function verifySmsCode(smsCode: string): IRootWidgetActions {
  return {
    type: ActionTypes.SMS_CODE_VERIFY_REQUEST,
    payload: {
      smsCode,
    },
  };
}

/** @description Экшен для обработки успешного запроса на проверку смс */
export function verifySmsCodeSuccess(): IRootWidgetActions {
  return {
    type: ActionTypes.SMS_CODE_VERIFY_SUCCESS,
  };
}

/** @description Экшен для обработки ошибки запроса на проверку смс */
export function verifySmsCodeError(error: string): IRootWidgetActions {
  return {
    type: ActionTypes.SMS_CODE_VERIFY_ERROR,
    payload: {
      smsCodeError: error,
    },
  };
}

/** @description Экшен для отправки запроса на резервирование столика */
export function guestBooking(): IRootWidgetActions {
  return {
    type: ActionTypes.GUEST_BOOKING_REQUEST,
  };
}

/** @description Экшен для обработки успешного запроса на резервирование столика */
export function guestBookingSuccess(): IRootWidgetActions {
  return {
    type: ActionTypes.GUEST_BOOKING_SUCCESS,
  };
}

/** @description Экшен для обработки ошибки запроса на резервирование столика */
export function guestBookingQueueProcessing(bookingId: number): IRootWidgetActions {
  return {
    type: ActionTypes.GUEST_BOOKING_QUEUE_PROCESSING,
    payload: {
      bookingId,
    },
  };
}

/** @description Экшен для обработки ошибки запроса на резервирование столика */
export function guestBookingError(error: IBookingErrorType, bookingData?: TErrorBookingData): IRootWidgetActions {
  return {
    type: ActionTypes.GUEST_BOOKING_ERROR,
    error,
    bookingData,
  };
}

/** @description Экшен для удаления бронирования */
export function deleteBooking(bookingId: number): IRootWidgetActions {
  return {
    type: ActionTypes.DELETE_RESERVE_REQUEST,
    payload: {
      bookingId,
    },
  };
}

/** @description Экшен для обработки успешного удаления бронирования */
export function deleteBookingSuccess(): IRootWidgetActions {
  return {
    type: ActionTypes.DELETE_RESERVE_SUCCESS,
  };
}

/** @description Экшен для обработки ошибки запроса на удаление бронирования */
export function deleteBookingError(error: string): IRootWidgetActions {
  return {
    type: ActionTypes.DELETE_RESERVE_ERROR,
    error,
  };
}
