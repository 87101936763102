import { connect } from 'react-redux';

import ThemeComp from './ThemeComp';
import { IAppStore } from '../../../types/IAppStore';
import { StateProps } from './IThemeComp';

function mapStateToProps(state: IAppStore): StateProps {
  const { settings } = state.root.restaurantInfo;
  return {
    colors: settings ? settings.colors : null,
  }
}

export default connect(mapStateToProps, null)(ThemeComp);

