import { createStyles } from '@material-ui/core';
import { Theme } from "@material-ui/core/styles";

export default (theme: Theme) => createStyles({
  content: {
    minHeight: 371,
    paddingTop: 12,
    display: 'flex',
    color: theme.palette.text.primary,
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});
