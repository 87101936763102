import React from 'react';

import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import locales from '../../locales';
import ReserveInfo from '../Common/ReserveInfo';

const styles = createStyles({
  wrap: {
    maxWidth: '450px',
    margin: '0 auto',
    textAlign: 'center',
  },
  restaurantInfoWrap: {
    textAlign: 'center',
    marginTop: 20,
  }
});

interface IProps extends WithStyles<typeof styles> {
  restaurantInfo?: any;
  bookingData: any;
}

const ActiveReserveError: React.FC<IProps> = (props) => {
  const {
    classes,
    restaurantInfo,
    bookingData,
  } = props;
  return (
    <div className={classes.wrap}>
      <Typography color="error" variant="h6">
        {locales.t('common.errors.reserveWidget.activeReserve')}
      </Typography>
      <Typography variant="body2">
        {locales.t('common.errors.reserveWidget.activeReserveDesc')} { restaurantInfo.phone }
      </Typography>
      <div className={classes.restaurantInfoWrap}>
        <Typography variant="body2" style={{ marginBottom: 8 }}>
          {locales.t('common.reserveWidget.yourCurrentReserve')}
        </Typography>
        <ReserveInfo
          restaurantInfo={restaurantInfo}
          bookingData={bookingData}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(ActiveReserveError);
