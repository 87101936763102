import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';
import { StateProps, DispatchProps } from './IReserveProcessingCont';

import {
  deleteBooking,
} from '../../actions/rootWidget/RootWidgetActions';
import ReserveProcessingCont from './ReserveProcessingCont';

function mapStateToProps(state: IAppStore): StateProps {
  const {
    bookingIsProcessing,
    bookingId,
    widgetId
  } = state.root;

  return {
    widgetId,
    bookingIsProcessing,
    bookingId,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): DispatchProps {
  return bindActionCreators({
    deleteBooking,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveProcessingCont);
