import React from 'react';

import { IProps } from "./IShowInitialized";

const ShowInitialized: React.FC<IProps> = ({
                                             initialized, children
                                           }) => {

  return <>
    {initialized ? children : null}
  </>;
}

export default ShowInitialized;
