import React, { useState, useEffect } from 'react';

import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { getCodePhoneNumber, normalizePhone, onlyNumbers } from '../../helpers/helpers';
import { phoneCode, getPhoneCodes } from '../../config/constants';

const styles = createStyles({
  fieldWrap: {
    display: 'flex',
    paddingTop: 16,
  },
  phoneField: {
    marginRight: 8,
  },
});

interface IProps extends WithStyles<typeof styles> {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  staticValue: string;
  label: string;
  readOnly: boolean;
  type: string;
  endAdornment: any;
  disabled?: boolean;
  locale?: string;
}

// TODO видимо нужно рефакторить логику переключения локалей
const PhoneFieldComp: React.FC<IProps> = ({
  classes,
  input,
  label,
  type,
  staticValue,
  readOnly,
  endAdornment,
  meta: { touched, error },
  disabled,
  locale,
}) => {
  const [value, change] = useState({
    code: phoneCode.ru.code,
    number: '',
  });

  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  useEffect(() => {
    if (input.value !== '') {
      change({
        ...getCodePhoneNumber(input.value),
      });
    } else {
      let codeValue;
      switch (locale) {
        case 'UA':
          codeValue = phoneCode.ukr.code;
          break;
        case 'EE':
          codeValue = phoneCode.est.code;
          break;
        case 'UZ':
          codeValue = phoneCode.uz.code;
          break;
        case 'AM':
          codeValue = phoneCode.am.code;
          break;
        case 'GE':
          codeValue = phoneCode.ge.code;
          break;
        case 'KZ':
          codeValue = phoneCode.kz.code;
          break;
        case 'AE':
          codeValue = phoneCode.ae.code;
          break;
        case 'US':
          codeValue = phoneCode.us.code;
          break;
        case 'AZ':
          codeValue = phoneCode.az.code;
          break;
        case 'BY':
          codeValue = phoneCode.by.code;
          break;
        case 'KG':
          codeValue = phoneCode.kg.code;
          break;
        case 'TM':
          codeValue = phoneCode.tm.code;
          break;
        default:
        case 'RU':
          codeValue = phoneCode.ru.code;
          break;
      }
      change({
        ...value,
        code: codeValue,
      });
    }
  }, []);

  const onChange = (e: any) => {
    const number = normalizePhone(e.target.value, value.code);

    if (e.target.value === '') {
      input.onChange('');
    } else {
      input.onChange(`${value.code}${onlyNumbers(number)}`);
    }

    change({
      ...value,
      [e.target.name]: number,
    });
  };

  const onChangeCode = (e: any) => {
    input.onChange(`${e.target.value}${value.number}`);

    change({
      code: e.target.value,
      number: '', // normalizePhone(value.number, e.target.value),
    });
  };

  const getLocale = (locale?: string) => {
    if (!locale) {
      return phoneCode.ru.code;
    }

    switch (locale) {
      case 'RU':
        return phoneCode.ru.code;
      case 'UA':
        return phoneCode.ukr.code;
      case 'EE':
        return phoneCode.est.code;
      case 'UZ':
        return phoneCode.uz.code;
      case 'AM':
        return phoneCode.am.code;
      case 'GE':
        return phoneCode.ge.code;
      case 'KZ':
        return phoneCode.kz.code;
      case 'AE':
        return phoneCode.ae.code;
      case 'US':
        return phoneCode.us.code;
      case 'AZ':
        return phoneCode.az.code;
      case 'BY':
        return phoneCode.by.code;
      case 'KG':
        return phoneCode.kg.code;
      case 'TM':
        return phoneCode.tm.code;
      default:
        return phoneCode.ru.code;
    }
  };

  let firstCountryKey: undefined | string;

  useEffect(() => {
    change({
      ...value,
      code: getLocale(locale),
    });

    firstCountryKey = locale && Object.prototype.hasOwnProperty.call(phoneCode, locale.toLocaleLowerCase())
                    ? undefined
                    : 'ru';
  }, [locale]);

  return (
    <FormControl error={touched && !!error}>
      <InputLabel focused={focused}>
        { label }
      </InputLabel>
      <div className={classes.fieldWrap}>
        <Select
          name="code"
          onChange={onChangeCode}
          disableUnderline
          className={classes.phoneField}
          defaultValue={getLocale(locale)}
          value={value.code}
          renderValue={(code) => {
            switch (code) {
              case phoneCode.ru.code:
                return phoneCode.ru.firstLabel;
              case phoneCode.ukr.code:
                return phoneCode.ukr.firstLabel;
              case phoneCode.est.code:
                return phoneCode.est.firstLabel;
              case phoneCode.uz.code:
                return phoneCode.uz.firstLabel;
              case phoneCode.am.code:
                return phoneCode.am.firstLabel;
              case phoneCode.ge.code:
                return phoneCode.ge.firstLabel;
              case phoneCode.kz.code:
                return phoneCode.kz.firstLabel;
              case phoneCode.ae.code:
                return phoneCode.ae.firstLabel;
              case phoneCode.us.code:
                return phoneCode.us.firstLabel;
              case phoneCode.az.code:
                return phoneCode.az.firstLabel;
              case phoneCode.by.code:
                return phoneCode.by.firstLabel;
              case phoneCode.kg.code:
                return phoneCode.kg.firstLabel;
              case phoneCode.tm.code:
                return phoneCode.tm.firstLabel;
              default:
                return phoneCode.ru.firstLabel;
            }
          }}
        >
          {getPhoneCodes(firstCountryKey).map(phoneCode => (
            <MenuItem value={phoneCode.code} key={phoneCode.key}>
              { phoneCode.secondLabel }
            </MenuItem>
          ))}
        </Select>
        <TextField
          name="number"
          fullWidth
          type={type}
          error={touched && !!error}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value.number}
          disabled={!!staticValue || disabled}
          InputProps={{
            readOnly: !!readOnly,
            endAdornment,
          }}
        />
      </div>
    </FormControl>
  );
};

export default withStyles(styles)(PhoneFieldComp);
