import moment, { Moment } from 'moment';
import { emptyString, weekDays } from '../config/constants';

/**
 * @description Округление текущей даты
 * @param roundBy - дефолтное значение 15
 * @returns {Object}
 */
const roundCurrentTimeBy = (roundBy: number = 15): Moment => {
  const interval = roundBy * 60 * 1000;
  // @ts-ignore
  return moment(Math.ceil(moment() / interval) * interval);
};

/** @description Проверка на завтра */
export function itsTomorrow(open: string, reserveTime: string): boolean {
  return parseInt(open) > parseInt(reserveTime);
}

/** @description Функция для получения следующего дня недели */
function getNextWeekDay(day: string): string {
  switch (day) {
    case weekDays.MONDAY:
      return weekDays.TUESDAY;
    case weekDays.TUESDAY:
      return weekDays.WEDNESDAY;
    case weekDays.WEDNESDAY:
      return weekDays.THURSDAY;
    case weekDays.THURSDAY:
      return weekDays.FRIDAY;
    case weekDays.FRIDAY:
      return weekDays.SATURDAY;
    case weekDays.SATURDAY:
      return weekDays.SUNDAY;
    case weekDays.SUNDAY:
      return weekDays.MONDAY;
    default: return emptyString;
  }
}

/**
 * @description Слияние даты и времени для запроса на бронирование
 * @param {date} date
 * @param {string} time
 */
const mergeDateAndTime = (date: any, time: any): Moment => {
  const parseTime = time.split(':');
  return moment(date).set(({
    hour: parseTime[0], minute: parseTime[1], second: 0, millisecond: 0,
  }));
};

/**
 * Формирование списка опций выбора доступного времени виджета
 * @param targetDate - Нужная дата
 * @param workTime - Время работы по дням
 * @returns {Array}
 */
const getWidgetTimeList = (targetDate: any, workTime: any) => {
  const timeLabels = [];
  const weekDay = moment(targetDate)
    .locale('en')
    .format('dddd')
    .toUpperCase();
  const { start, end } = workTime[weekDay];

  if (start && end) {
    const endParse = end.split(':');
    const endMoment = moment().hours(endParse[0]).minutes(endParse[1]);

    const startParse = start.split(':');
    let startMoment = moment().hours(startParse[0]).minutes(startParse[1]);
    const isToday = moment(0, 'HH').diff(targetDate, 'day') === 0;

    // Делаем невозможность выбора в прошлом
    if (isToday && moment() > startMoment) {
      startMoment = roundCurrentTimeBy();
    }

    // Если открытие позже закрытия, считаем и на следующий день
    if (parseInt(startParse[0]) > parseInt(endParse[0])) {
      endMoment.add(1, 'day');
    }

    const timesToStop = endMoment.diff(startMoment, 'minutes') / 15;

    for (let i = 0; i <= timesToStop; i += 1) {
      startMoment.add(i === 0 ? 0 : 15, 'minutes');
      timeLabels.push(startMoment.format('HH:mm'));
    }
  }

  return timeLabels;
};

/** @description Парсинг даты для отправки на бэк */
const parseDateForReq = (date: string | Moment): string => moment(date).format('YYYY-MM-DDTHH:mm:ss');

/** @description Мержим расписание для формирования списка времени работы ресторана для виджета */
const getWidgetMergedSchedule = (scheduleList: Array<any>) => {
  return scheduleList.reduce((schedule: any, group) => {
    if (group.records.length > 1) {
      const nextDay = getNextWeekDay(group.day);
      const time = {
        start: '',
        end: '',
      };

      group.records.forEach((i: any) => i.day === group.day
        ? time.start = i.start
        : time.end = i.end);

      return {
        ...schedule,
        [group.day]: {
          ...schedule[group.day],
          start: time.start,
          end: time.end,
        },
        [nextDay]: {
          ...schedule[nextDay],
          previous: time.end,
        },
      };
    }

    return {
      ...schedule,
      [group.day]: {
        ...schedule[group.day],
        start: group.records[0].start,
        end: group.records[0].end,
      },
    };
  }, {});
};

export {
  roundCurrentTimeBy,
  mergeDateAndTime,
  getWidgetMergedSchedule,
  parseDateForReq,
  getWidgetTimeList,
};
