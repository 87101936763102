import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import styles from './styles';
import locales from '../../locales';
import { IProps } from './IRootWidget';
import { reserveWidgetState } from '../../config/constants';
import ReserveForm from '../ReserveFormCont';
import SMSCodeForm from '../SmsCodeFormCont';
import ReserveProcessing from '../ReserveProcessingCont';
import ReserveSuccess from '../ReserveSuccessCont';
import ErrorCont from '../ErrorCont';
import { isFullScreen } from '../../helpers/helpers';
import { fallbackValue } from '../../helpers/constants'

class RootWidget extends PureComponent<IProps, {}> {
  render() {
    const {
      classes,
      widgetState,
      restaurantInfo,
    } = this.props;

    let widgetContent = null;

    switch (widgetState) {
      case reserveWidgetState.FORM_STATE:
        widgetContent = (
          <ReserveForm />
        );
        break;

      case reserveWidgetState.SMS_VERIFY_STATE:
        widgetContent = (
          <SMSCodeForm />
        );
        break;

      case reserveWidgetState.PROCESSING_STATE:
        widgetContent = (
          <ReserveProcessing />
        );
        break;

      case reserveWidgetState.SUCCESS_STATE:
        widgetContent = (
          <ReserveSuccess />
        );
        break;
      case reserveWidgetState.ERROR: {
        widgetContent = (
          <ErrorCont />
        );
        break;
      }
      default:
        widgetContent = null;
        break;
    }

    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        fullScreen={isFullScreen()}
        scroll="body"
        open
      >
        { (restaurantInfo.settings || fallbackValue.settings).isShowHeader
          ? (
            <DialogTitle className={classes.title}>
              {`${locales.t('common.reserveWidget.reserveTitle')} ${restaurantInfo.name}`}
            </DialogTitle>
          )
          : null}
        <DialogContent className={classes.content}>
          { widgetContent }
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(RootWidget)
