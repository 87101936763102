import { combineReducers } from 'redux';
import { connectRouter as router } from 'connected-react-router';
import { reducer as form } from 'redux-form';

import root from './RootWidgetReducer';
import reserveForm from './ReseveFormReducer';

export default (history: any) => combineReducers({
  reserveForm,
  root,
  router: router(history),
  form,
});
