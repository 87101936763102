import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';
import { StateProps, DispatchProps } from './ISmsCodeFormCont';

import {
  verifySmsCode,
  resendRegistrationRequest,
} from '../../actions/rootWidget/RootWidgetActions';
import SmsCodeForm from './SmsCodeForm';

function mapStateToProps(state: IAppStore): StateProps {
  const {
    timeLeft,
    smsCodeError,
    bookingReqData,
  } = state.root;

  return {
    timeLeft,
    smsCodeError,
    phoneNumber: bookingReqData.phone,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): DispatchProps {
  return bindActionCreators({
    verifySmsCode,
    resendRegistrationRequest,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SmsCodeForm);
