import React, { PureComponent } from 'react';

import { IProps } from './IReserveProcessingCont';
import ReserveProcessing from '../../components/ReserveProcessing';

class ReserveProcessingCont extends PureComponent<IProps, {}> {
  /** @description Ручка для отмены брони */
  handleDeleteBooking = () => {
    const {
      bookingId,
      deleteBooking,
    } = this.props;

    if (bookingId) {
      deleteBooking(bookingId);
    }
  };

  render() {
    const {
      bookingIsProcessing,
    } = this.props;

    return (
      <ReserveProcessing
        handleDeleteBooking={this.handleDeleteBooking}
        bookingIsProcessing={bookingIsProcessing}
      />
    );
  }
}

export default ReserveProcessingCont
