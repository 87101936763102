import React from 'react';

import {
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import locales from '../../locales';

const styles = (theme: Theme) => createStyles({
  processCont: {
    padding: '100px 48px 44px',
    maxWidth: '500px',
    margin: '0 auto',
    textAlign: 'center',
  },
  processText: {
    fontSize: '16px',
    color: theme.palette.text.primary,
    margin: '24px 0 42px',
  },
  processCancel: {
    width: '100%',
    height: 52,
    fontSize: '18px',
  },
});

interface IProps extends WithStyles<typeof styles> {
  bookingIsProcessing: boolean;
  handleDeleteBooking: () => void;
}

const Index: React.FC<IProps> = ({classes, bookingIsProcessing, handleDeleteBooking}) => (
  <div className={classes.processCont}>
    <CircularProgress size={48} />
    <Typography
      className={classes.processText}
      color="initial"
      variant="body1"
    >
      { bookingIsProcessing
        ? locales.t('common.reserveWidget.queueProcessing')
        : locales.t('common.reserveWidget.requestProcessing')
      }
    </Typography>
    <Button
      onClick={handleDeleteBooking}
      size="large"
      className={classes.processCancel}
      variant="contained"
      color="primary"
    >
      {locales.t('common.cancel')}
    </Button>
  </div>
);

export default withStyles(styles)(Index);
