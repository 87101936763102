import * as ActionTypes from './ReserveFormActionsTypes';
import { IReserveFormActions } from './IReserveFormActions';

/** @description Экшен для отправки запроса на получение свободных столов */
export function getFreeTables(date: string, persons: number): IReserveFormActions {
  return {
    type: ActionTypes.GET_TABLES_COUNT_REQUEST,
    payload: {
      date,
      persons,
    },
  };
}

/** @description Экшен для обработки успешного запроса на получение столов */
export function getFreeTablesSuccess(freeTables: number): IReserveFormActions {
  return {
    type: ActionTypes.GET_TABLES_COUNT_SUCCESS,
    payload: {
      freeTables,
    },
  };
}

/** @description Экшен для обработки ошибки запроса на получение столов */
export function getFreeTablesError(): IReserveFormActions {
  return {
    type: ActionTypes.GET_TABLES_COUNT_ERROR,
  };
}

/** @description Экшен для отправки запроса на получение расписания ресторана */
export function getRestaurantSchedule(date: string): IReserveFormActions {
  return {
    type: ActionTypes.RESTAURANT_SCHEDULE_REQUEST,
    payload: {
      date,
    },
  };
}

/** @description Экшен для успешного запроса на получение расписания ресторана */
export function getRestaurantScheduleSuccess(scheduleOptions: Array<string>): IReserveFormActions {
  return {
    type: ActionTypes.RESTAURANT_SCHEDULE_SUCCESS,
    payload: {
      scheduleOptions,
    },
  };
}

/** @description Экшен для запроса максимальной вместимости столов */
export function getMaxPersonCount(widgetId: number): IReserveFormActions {
  return {
    type: ActionTypes.GET_MAX_PERSON_COUNT_REQUEST,
    payload: {
      widgetId,
    },
  };
}

/** @description Экшен для обработки успешного запроса максимальной вместимости столов */
export function getMaxPersonCountSuccess(persons: number): IReserveFormActions {
  return {
    type: ActionTypes.GET_MAX_PERSON_COUNT_SUCCESS,
    payload: {
      persons,
    },
  };
}

/** @description Экшен для обработки ошибки запроса максимальной вместимости столов */
export function getMaxPersonCountError(persons: number): IReserveFormActions {
  return {
    type: ActionTypes.GET_MAX_PERSON_COUNT_SUCCESS,
    payload: {
      persons,
    },
  };
}
