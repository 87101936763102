import React, { ChangeEvent } from 'react';
import { WrappedFieldProps } from 'redux-form';
import classNames from 'classnames';

import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import locales from '../../locales';
import { emptyTime } from '../../config/constants';

const styles = createStyles({
  isError: {
    color: 'red',
  },
  fontSize: {
    fontSize: 14 // КОСТЫЛЬ ДЛЯ IOS
  }
});

interface IProps extends WrappedFieldProps, WithStyles<typeof styles> {
  time: Array<string>;
  selectedTime: string;
  handleSetTime: (event: ChangeEvent<HTMLSelectElement>) => void;
}

/** @description Селектор для выбора времени бронирования */
const WidgetReserveTimeSelect: React.FC<IProps> = ({
  input,
  meta: { touched, error },
  time,
  classes,
}) => {
  const isError = touched && error;

  return (
    <FormControl fullWidth error={isError} disabled={!time || time.length === 0}>
      <InputLabel shrink htmlFor="age-native-label-placeholder">
        {locales.t('common.reserveModal.time')}
      </InputLabel>
      <Select
        className={classNames(classes.fontSize, { [classes.isError]: isError })}
        {...input}
        inputProps={{
          name: 'time',
          id: 'time',
        }}
        fullWidth
      >
        {
          input.value === emptyTime && (
            <MenuItem selected value={emptyTime} key={emptyTime}>
              { locales.t('common.reserveModal.emptyTime') }
            </MenuItem>
          )
        }
        {
          time.map(t => (
            <MenuItem alignItems="center" key={t} value={t}>
              { t }
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(WidgetReserveTimeSelect);
