import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Typography from '@material-ui/core/Typography/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import HelperText from '@material-ui/core/FormHelperText';

interface IProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  label: string;
}

const CheckBoxComp: React.FC<IProps> = ({
  input,
  label,
  meta: { touched, error },
}) => {
  // eslint-disable-next-line
  delete input.value; // грязный хак

  return (
    <>
      <FormControlLabel
        control={(
          <Checkbox
            color="primary"
            {...input}
          />
        )}
        label={(
          <Typography variant="caption">
            { label }
            {touched && error && (
              <HelperText error>{ error }</HelperText>
            )}
          </Typography>
        )}
      />
    </>
  );
};

export default CheckBoxComp;
