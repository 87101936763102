import { IBookingErrorType } from '../types/IRootWidgetStore';

export const reserveWidgetState = {
  FORM_STATE: 'FORM_STATE',
  SMS_VERIFY_STATE: 'SMS_VERIFY_STATE',
  PROCESSING_STATE: 'PROCESSING_STATE',
  SUCCESS_STATE: 'SUCCESS_STATE',
  ERROR: 'ERROR',
};

export const emptyTime = '-';

export const emptyString = '-';

export const weekDays = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
};

export const errorType: {
  [types: string]: IBookingErrorType
} = {
  unknownError: 'unknownError',
  activeReserve: 'activeReserve'
};

interface IPhoneCode {
  key: string;
  code: string;
  firstLabel: string;
  secondLabel: string;
  title: string;
}

interface IPhoneCodes {
  [key: string]: IPhoneCode;
}

export const phoneCode: IPhoneCodes = {
  ru: {
    key: 'ru',
    code: '+7',
    firstLabel: '🇷🇺 (+7)',
    secondLabel: '🇷🇺 Россия (+7)',
    title: 'Россия',
  },
  ukr: {
    key: 'ukr',
    code: '+380',
    firstLabel: '🇺🇦 (+380)',
    secondLabel: '🇺🇦 Украина (+380)',
    title: 'Украина',
  },
  est: {
    key: 'est',
    code: '+372',
    firstLabel: '🇪🇪 (+372)',
    secondLabel: '🇪🇪 Эстония (+372)',
    title: 'Эстония',
  },
  uz: {
    key: 'uz',
    code: '+998',
    firstLabel: '🇺🇿 (+998)',
    secondLabel: '🇺🇿 Узбекистан (+998)',
    title: 'Узбекистан',
  },
  am: {
    key: 'am',
    code: '+374',
    firstLabel: '🇦🇲 (+374)',
    secondLabel: '🇦🇲 Армения (+374)',
    title: 'Армения',
  },
  ge: {
    key: 'ge',
    code: '+995',
    firstLabel: '🇬🇪 (+995)',
    secondLabel: '🇬🇪 Грузия (+995)',
    title: 'Грузия',
  },
  kz: {
    key: 'kz',
    code: '+77',
    firstLabel: '🇰🇿 (+77)',
    secondLabel: '🇰🇿 Казахстан (+77)',
    title: 'Казахстан',
  },
  ae: {
    key: 'ae',
    code: '+971',
    firstLabel: '🇦🇪 (+971)',
    secondLabel: '🇦🇪 ОАЭ (+971)',
    title: 'ОАЭ',
  },
  us: {
    key: 'us',
    code: '+1',
    firstLabel: '🇺🇸 (+1)',
    secondLabel: '🇺🇸 США (+1)',
    title: 'США',
  },
  az: {
    key: 'az',
    code: '+994',
    firstLabel: '🇦🇿 (+994)',
    secondLabel: '🇦🇿 Азербайджан (+994)',
    title: 'Азербайджан',
  },
  by: {
    key: 'by',
    code: '+375',
    firstLabel: '🇧🇾 (+375)',
    secondLabel: '🇧🇾 Белоруссия (+375)',
    title: 'Белоруссия',
  },
  kg: {
    key: 'kg',
    code: '+996',
    firstLabel: '🇰🇬 (+996)',
    secondLabel: '🇰🇬 Кыргызстан (+996)',
    title: 'Кыргызстан',
  },
  tm: {
    key: 'tm',
    code: '+993',
    firstLabel: '🇹🇲 (+993)',
    secondLabel: '🇹🇲 Туркменистан (+993)',
    title: 'Туркменистан',
  },
};

const sortPhoneCodeByTitle = (a: IPhoneCode, b: IPhoneCode) => {
  const nameA = a.title.toUpperCase();
  const nameB = b.title.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

export const getPhoneCodes = (firstCountryKey?: string): Array<IPhoneCode> => {
  const codes = Object.values(phoneCode).sort(sortPhoneCodeByTitle);

  if (firstCountryKey) {
    return [
      phoneCode[firstCountryKey],
      ...codes.filter(item => item.key !== firstCountryKey),
    ];
  }

  return codes;
};
