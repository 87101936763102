import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import TextField from '@material-ui/core/TextField';

interface IProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  staticValue: string;
  label: string;
  readOnly: boolean;
  type: string;
}

const TextFieldComp: React.FC<IProps> = ({
  input, label, type, staticValue, readOnly, meta: { touched, error },
}) => (
  <TextField
    fullWidth
    label={(touched && !!error) ? error : label}
    error={touched && !!error}
    type={type}
    {...input}
    disabled={!!staticValue}
    InputProps={{
      readOnly: !!readOnly,
    }}
  />
);

export default TextFieldComp;
