import {
  TWidgetColors,
  TWidgetSettings,
} from '../types/IRootWidgetStore';

type Settings = {
  settings: Required<TWidgetSettings> & {
    colors: Required<TWidgetColors>
  }
}

export const fallbackColors: Required<TWidgetColors> = {
  mainColor: '#6ae9c5',
  formBackground: '#ffffff',
  pageBackground: '#737577',
  formHeaderColor: '#323232',
  formBaseColor: '#323232',
  formFieldColor: '#aaaaaa',
}

export const fallbackValue: Settings = {
  settings: {
    isShowHeader: true,
    isEmailRequired: false,
    isLastNameRequired: false,
    colors: fallbackColors,
  },
};
