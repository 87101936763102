import React, { ChangeEvent, PureComponent } from 'react';
import { Field } from 'redux-form';

import {
  withStyles, createStyles, WithStyles, Theme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';

import { mergeDateAndTime, itsTomorrow  } from '../../helpers/dateHelpers';
import PersonSelect from './PersonSelect';
import DatePicker from './DatePicker';
import { emptyTime } from '../../config/constants';
import WidgetReserveTimeSelect from './WidgetReserveTimeSelect';
import TextField from '../Common/TextFieldComp';
import locales from '../../locales';
import TextAreaComp from '../Common/TextAreaComp';
import Typography from '@material-ui/core/Typography/Typography';
import classNames from 'classnames';
import CheckBoxComp from '../Common/CheckBoxComp';
import PhoneField from './PhoneField';

const styles = (theme: Theme) => createStyles({
  fieldStyle: {
    paddingTop: 23,
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: '0 8px',
    width: '100%',
  },
  tablesNumber: {
    fontSize: '29px',
    marginLeft: 5,
    color: theme.palette.primary.main,
  },
  tablesNumberError: {
    color: '#f44336',
  },
  tablesNumberText: {
    height: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorWrap: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.text.secondary,
  },
  errorText: {
    position: 'absolute',
  }
});

interface IProps extends WithStyles<typeof styles> {
  valueChanged: (date: string, persons: number) => void;
  getSchedule: (date: string) => void;
  scheduleOptions: Array<string>;
  maxPersons: number;
  tables: number;
  freeTablesError: boolean;
  countryCode: string;
}

interface IState {
  personsCount: number;
  date: string;
  selectedTime: string;
  agreement: boolean;
}

// TODO Вынести логику запроса свободных столов в сагу или контейнер формы.
class BookingFields extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      personsCount: 0,
      date: emptyTime,
      selectedTime: emptyTime,
      agreement: false,
    };
  }

  onChangeDate = (date: string) => {
    const { getSchedule } = this.props;
    const { selectedTime } = this.state;

    this.setState({ date }, this.updateBookingData);
    getSchedule(mergeDateAndTime(date, selectedTime).format());
  };

  onChangeTime = (time: string) => {
    this.setState({ selectedTime: time }, this.updateBookingData);
  };

  onChangePersonsCount = (personsCount: string) => {
    this.setState({
      personsCount: parseInt(personsCount, 10),
    }, this.updateBookingData);
  };

  onChangeAgreement = (checked: boolean) => {
    this.setState({
      agreement: checked,
    }, this.updateBookingData);
  };

  updateBookingData() {
    const { date, personsCount, selectedTime } = this.state;
    const { scheduleOptions, valueChanged } = this.props;

    if (personsCount && selectedTime && selectedTime !== emptyTime) {
      const mergedDate = mergeDateAndTime(date, selectedTime);

      if (itsTomorrow(scheduleOptions[0], selectedTime)) {
        mergedDate.add(1, 'd');
      }
      valueChanged(mergedDate.format(), personsCount);
    }
  }

  render() {
    const { personsCount, date, selectedTime } = this.state;
    const { scheduleOptions, maxPersons, tables, freeTablesError, classes, countryCode} = this.props;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Field
            name="persons"
            component={PersonSelect}
            value={personsCount}
            maxCapacity={maxPersons}
            onChange={
              (e: ChangeEvent<HTMLSelectElement>) => this.onChangePersonsCount(e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            name="date"
            component={DatePicker}
            value={date}
            onChange={
              (date: any) => this.onChangeDate(date)
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            name="time"
            component={WidgetReserveTimeSelect}
            time={scheduleOptions || []}
            value={selectedTime}
            onChange={
              (e: ChangeEvent<HTMLSelectElement>) => this.onChangeTime(e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            name="name"
            component={TextField}
            label={locales.t('common.reserveModal.name')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            name="phone"
            component={PhoneField}
            label={locales.t('common.reserveModal.phone')}
            locale={countryCode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            name="comment"
            component={TextAreaComp}
            multiline
            label={locales.t('common.reserveModal.comment')}
            rows={1}
          />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.tablesNumberText}>
          {
            !tables && !freeTablesError ? (
              <Typography
                variant="caption"
                style={{ lineHeight: 1, }}
              >
                { locales.t('common.reserveWidget.tablesForReserveInit') }
              </Typography>
            ) : (
              <Typography
                variant="body1"
                style={{ lineHeight: 1, }}
              >
                { locales.t('common.reserveWidget.tablesForReserve') }
                <span className={
                  classNames(
                    classes.tablesNumber,
                    {[classes.tablesNumberError]: freeTablesError}
                  )
                }
                >
                { tables }
              </span>
              </Typography>
            )
          }
          {
            freeTablesError && (
              <div className={classes.errorWrap}>
                <Typography className={classes.errorText} variant="caption" color="error">
                  { locales.t('common.reserveWidget.tablesForReserveError') }
                </Typography>
              </div>
            )
          }
        </Grid>
        {/*
        <Grid item xs={12}>
          <Field
            name="smsInfo"
            type="checkbox"
            label={locales.t('common.reserveWidget.smsInfo')}
            component={CheckBoxComp}
          />
        </Grid>
        */}
        <Grid item xs={12} style={{ paddingTop: '12pt', paddingBottom: '18pt' }}>
          <Field
            name="agreement"
            type="checkbox"
            label={
              <Typography variant="caption">
                { locales.t('common.reserveWidget.agreementPhrase1') }
                {' '}
                <a
                  href="https://guestme.ru/privacy_policy/"
                  style={{ textDecoration: 'underline' }}
                  target="_blank"
                >
                  { locales.t('common.reserveWidget.agreementPhrase2') }
                </a>
                {' '}
                { locales.t('common.reserveWidget.agreementPhrase3') }
              </Typography>
            }
            component={CheckBoxComp}
            onChange={
              (e: ChangeEvent<HTMLInputElement>) => this.onChangeAgreement(e.target.checked)
            }
          />
          {/*
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) => this.onChangeAgreement(e.target.checked)
                }
              />
            }
            label={
              <Typography variant="caption">
                { locales.t('common.reserveWidget.agreementPhrase1') }
                {' '}
                <a
                  href="https://guestme.ru/privacy_policy/"
                  style={{ textDecoration: 'underline' }}
                  target="_blank"
                >
                  { locales.t('common.reserveWidget.agreementPhrase2') }
                </a>
                {' '}
                { locales.t('common.reserveWidget.agreementPhrase3') }
              </Typography>
            }
          />
          */}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(BookingFields);
