import { delay } from 'redux-saga/effects'

type RequestCall<T, TP> = (args: TP) => Promise<T>;

export function* queryWithRetry<T, R, P>(requestFunction: RequestCall<T, P>, params: P, retries = 5) {
  let lastError = null;
  for (let i = 0; i < retries; i++) {
    try {
      return yield requestFunction(params);
    } catch (err) {
      if (err && err.response && err.response.status == 404) {
        throw new Error(err);
      }

      lastError = err;
      if (i < (retries - 1)) {
        yield delay((i + 1) * 3000);
      }
    }
  }

  // Stop failed several attempts
  throw new Error(lastError || 'request failed');
}
