import { all } from 'redux-saga/effects';

import rootWidget from './RootWidgetSagas';
import reserveFormSaga from './ReserveFormSaga';

export default function* rootSaga() {
  yield all([
    rootWidget(),
    reserveFormSaga(),
  ]);
}
