import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './index.css';
import App from './containers/App/App';

const { REACT_APP_SENTRY_DSN, REACT_APP_NAME, REACT_APP_VERSION } = process.env;

if (REACT_APP_SENTRY_DSN) {
  const sentryConfig = {
    dsn: REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 0.5,
  };

  if (REACT_APP_NAME && REACT_APP_VERSION) {
    sentryConfig.release = `${REACT_APP_NAME}@${REACT_APP_VERSION}`;
  }

  Sentry.init(sentryConfig);
}

ReactDOM.render(<App />, document.getElementById('root'));
