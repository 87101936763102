import React, { PureComponent } from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import classNames from 'classnames';

import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from "@material-ui/core";

import locales from '../../locales';

import BookingFields from './BookingFields';
import { isFullScreen } from '../../helpers/helpers';

const styles = (theme: Theme) => createStyles({
  formWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  btnStyle: {
    fontSize: '18px',
    width: '100%',
    height: 52,
    marginBottom: '12px',
  },
  logo: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    }
  },
  provideDesc: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.text.hint,
    paddingBottom: 4,

    '& .MuiTypography-root': {
      color: theme.palette.text.hint,
    }
  },
  provideDescFullScreen: {
    display: 'block'
  }
});

interface IProps extends WithStyles<typeof styles> {
  handleSubmit: (data: any) => void;
  tables: number;
  isLoading: boolean;
  maxPersons: number;
  valueChanged: (date: string, persons: number) => void;
  getSchedule: (date: string) => void;
  scheduleOptions: Array<string>;
  freeTablesError: boolean;
  countryCode: string;
}

class BookingForm extends PureComponent<IProps, {}> {
  render() {
    const {
      classes,
      handleSubmit,
      tables,
      isLoading,
      scheduleOptions,
      getSchedule,
      valueChanged,
      maxPersons,
      freeTablesError,
      countryCode,
    } = this.props;

    const fullScreen = isFullScreen();

    return (
      <form
        className={classes.formWrap}
        onSubmit={handleSubmit}
      >
        <BookingFields
          getSchedule={getSchedule}
          scheduleOptions={scheduleOptions}
          valueChanged={valueChanged}
          maxPersons={maxPersons}
          tables={tables}
          freeTablesError={freeTablesError}
          countryCode={countryCode}
        />
        <div>
          <Button
            disabled={isLoading || freeTablesError}
            className={classes.btnStyle}
            variant="contained"
            type="submit"
            color="primary"
          >
            {locales.t('common.reserveWidget.reserve')}
            { isLoading && <CircularProgress size={20} /> }
          </Button>
          <div
            style={{ textAlign: 'center' }}
            className={classNames(classes.provideDesc, { [classes.provideDescFullScreen]: fullScreen })}
          >
            <Typography variant="caption" style={{ fontSize: 9 }} >
              {locales.t('common.reserveWidget.serviceProvided')}
              &nbsp;
              <a
                className={classes.logo}
                href={"https://guestme.ru/"}
                target="_blank"
              >
                GuestMe
              </a>
              &nbsp;
              { `— ${locales.t('common.reserveWidget.serviceProvidedDescription')}` }
            </Typography>
            {/*
            <Typography variant="caption" style={{ fontSize: 9 }} >
              {locales.t('common.reserveWidget.servicePolicyDesc')}
              <a
                href={"https://guestme.ru/privacy_policy/"}
                style={{ textDecoration: 'underline' }}
                target="_blank"
              >
                { locales.t('common.reserveWidget.servicePolicyDescLink') }
              </a>
            </Typography>
            */}
          </div>
        </div>
      </form>
    );
  }
}

const validate = ({
  name, phone, persons, date, time, agreement,
}: any) => {
  const errors: any = {};

  if (persons === 0) errors.persons = locales.t('common.reserveWidget.formErrors.personsError');
  if (date === '-') errors.date = locales.t('common.reserveWidget.formErrors.dateError');
  if (time === '-') errors.time = locales.t('common.reserveWidget.formErrors.timeError');
  if (!name) errors.name = locales.t('common.reserveWidget.formErrors.nameError');
  if (!phone) errors.phone = locales.t('common.reserveWidget.formErrors.phoneError');

  if (!agreement) {
    errors.agreement = locales.t('common.reserveWidget.formErrors.agreementError');
  }

  return errors;
};

export default compose<any, any>(
  withStyles(styles),
  reduxForm({
    form: 'reserveWidgetForm',
    validate,
  }),
)(BookingForm);
