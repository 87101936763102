import React, { PureComponent } from 'react';

import {
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';

import locales from '../../locales';
import ReserveInfo from '../Common/ReserveInfo';

const styles = (theme: Theme) => createStyles({
  successCont: {
    maxWidth: '450px',
    margin: '0 auto',
    textAlign: 'center',
    paddingTop: '32px',
  },
  restaurantInfoWrap: {
    textAlign: 'center',
    marginTop: 33,
  },
  body1: {
    color: theme.palette.text.primary,
  }
});

interface IProps extends WithStyles<typeof styles> {
  bookingReqData: any
  restaurantInfo: any;
}

class Index extends PureComponent<IProps> {
  render() {
    const {
      classes,
      bookingReqData,
      restaurantInfo,
    } = this.props;

    return (
      <div className={classes.successCont}>
        <Typography color="primary" variant="h5">
          {locales.t('common.reserveWidget.confirmedTitle')}
        </Typography>
        <Typography variant="body1" className={classes.body1}>
          {locales.t('common.reserveWidget.confirmedDesc')}
        </Typography>
        <div className={classes.restaurantInfoWrap}>
          <ReserveInfo
            restaurantInfo={restaurantInfo}
            bookingData={bookingReqData}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Index);
