import { IAppActions } from '../types/IAppActions';
import { IRootWidgetStore } from '../types/IRootWidgetStore';
import * as RootWidgetActionsTypes from '../actions/rootWidget/RootWidgetActionsTypes';
import { reserveWidgetState } from '../config/constants';
import { getWidgetIdFromUrl } from '../helpers/helpers';

export const initState: IRootWidgetStore = {
  widgetId: getWidgetIdFromUrl(),
  widgetState: reserveWidgetState.FORM_STATE,
  confirmCodeRequested: false,
  timeLeft: 0,
  restaurantInfoInitialized: true,
  restaurantInfo: {
    address: ' ',
    name: ' ',
    phone: ' ',
    countryCode: '',
  },
  bookingIsProcessing: false,
  smsCodeError: '',
  bookingId: undefined,
  bookingError: undefined,
  bookingReqData: {
    comment: '',
    persons: 0,
    time: '-',
    date: '-',
    dateForReq: '',
    fullName: '',
    phone: '',
    smsNotificationIsEnabled: true,
  }
};

export default function reducer(state = initState, action: IAppActions): IRootWidgetStore {
  switch (action.type) {
    case RootWidgetActionsTypes.INIT_APP_RESTAURANT_INFO: {
      return {
        ...state,
        restaurantInfo: action.payload.restaurantInfo,
        restaurantInfoInitialized: true,
      }
    }

    case RootWidgetActionsTypes.REGISTRATION_REQUEST:
      return {
        ...state,
        confirmCodeRequested: true,
        bookingReqData: action.payload.bookingData,
      };

    case RootWidgetActionsTypes.REGISTRATION_REQUEST_SUCCESS:
      let widgetState = reserveWidgetState.SMS_VERIFY_STATE
      if (state.restaurantInfo.countryCode !== 'RU') {
        widgetState = reserveWidgetState.PROCESSING_STATE
      }

      return {
        ...state,
        widgetState: widgetState,
        confirmCodeRequested: true,
        timeLeft: action.payload.timeLeft,
      };

    case RootWidgetActionsTypes.REGISTRATION_REQUEST_ERROR:
      return {
        ...state,
        confirmCodeRequested: false,
      };

    case RootWidgetActionsTypes.SMS_CODE_VERIFY_REQUEST:
      return {
        ...state,
        smsCodeError: '',
      };

    case RootWidgetActionsTypes.SMS_CODE_VERIFY_SUCCESS:
      return {
        ...state,
        widgetState: reserveWidgetState.PROCESSING_STATE,
      };

    case RootWidgetActionsTypes.SMS_CODE_VERIFY_ERROR:
      return {
        ...state,
        smsCodeError: action.payload.smsCodeError,
      };

    case RootWidgetActionsTypes.GUEST_BOOKING_SUCCESS:
      return {
        ...state,
        widgetState: reserveWidgetState.SUCCESS_STATE,
      };

    case RootWidgetActionsTypes.GUEST_BOOKING_ERROR:
      return {
        ...state,
        widgetState: reserveWidgetState.ERROR,
        bookingError: {
          type: action.error,
          bookingData: action.bookingData,
        },
      };

    case RootWidgetActionsTypes.GUEST_BOOKING_QUEUE_PROCESSING:
      return {
        ...state,
        bookingIsProcessing: true,
        bookingId: action.payload.bookingId,
      };

    case RootWidgetActionsTypes.DELETE_RESERVE_SUCCESS:
      return {
        ...state,
        bookingIsProcessing: false,
        widgetState: reserveWidgetState.FORM_STATE,
      };

    case RootWidgetActionsTypes.INIT_APP_WIDGET_STATE: {
      if (action.payload.state === reserveWidgetState.PROCESSING_STATE) {
        return {
          ...state,
          bookingReqData: action.payload.reserveInfo,
          widgetState: reserveWidgetState.PROCESSING_STATE
        }
      }

      return {
        ...state,
        bookingReqData: action.payload.reserveInfo,
      }
    }

    default:
      return state;
  }
}
