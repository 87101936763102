import React, { PureComponent } from 'react';

import { IProps } from './IReserveSuccess';
import ReserveSuccess from '../../components/ReserveSuccess';

class RootWidget extends PureComponent<IProps, {}> {
  render() {
    const {
      restaurantInfo,
      bookingReqData,
    } = this.props;

    return (
      <ReserveSuccess
        bookingReqData={bookingReqData}
        restaurantInfo={restaurantInfo}
      />
    );
  }
}

export default RootWidget
