import React, { PureComponent } from 'react';
import BookingForm from '../../components/BookingForm';
import { IProps } from './IReserveForm';
import { itsTomorrow, mergeDateAndTime } from '../../helpers/dateHelpers';

class ReserveFormCont extends PureComponent<IProps> {
  handleSubmitForm = (data: any): void => {
    const { sendRegistrationRequest, freeTables, scheduleOptions } = this.props;

    if (freeTables > 0) {
      const {
        comment, date, name, persons, phone, smsInfo, time
      } = data;

      const mergeDate = mergeDateAndTime(date, time);

      const dateForReq = itsTomorrow(scheduleOptions[0], time)
        ? mergeDate.add(1, 'day').format()
        : mergeDate.format();

      sendRegistrationRequest({
        comment,
        persons,
        date: mergeDateAndTime(date, time).format(),
        dateForReq,
        time: time,
        fullName: name,
        phone,
        smsNotificationIsEnabled: !!smsInfo,
      });
    }
  };

  onBookingFieldsChange = (
    date: string,
    personsCount: number,
  ) => {
    const { getFreeTables } = this.props;
    getFreeTables(date, personsCount);
  };

  onScheduleRequest = (date: string) => {
    const { getRestaurantSchedule} = this.props;

    getRestaurantSchedule(date);
  };

  render() {
    const {
      isLoading,
      freeTables,
      maxPersons,
      scheduleOptions,
      bookingData,
      freeTablesError,
      countryCode,
    } = this.props;

    return (
      <BookingForm
        isLoading={isLoading}
        freeTablesError={freeTablesError}
        tables={freeTables}
        maxPersons={maxPersons}
        countryCode={countryCode}
        scheduleOptions={scheduleOptions}
        onSubmit={this.handleSubmitForm}
        valueChanged={this.onBookingFieldsChange}
        getSchedule={this.onScheduleRequest}
        initialValues={{
          persons: bookingData.persons,
          date: bookingData.date,
          time: bookingData.time,
          phone: bookingData.phone,
          name: bookingData.fullName,
          comment: bookingData.comment,
          smsInfo: bookingData.smsNotificationIsEnabled,
        }}
      />
    );
  }
}

export default ReserveFormCont;
