import React from 'react';
import moment from 'moment';

import {
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GroupIcon from '@material-ui/icons/Group';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import locales from '../../locales';
import { TRestaurant } from '../../types/IRootWidgetStore';

const styles = (theme: Theme) => createStyles({
  confirmedPaper: {
    padding: '20px 24px',
    textAlign: 'left',
  },
  confirmedInfo: {
    margin: '20px 0 8px',
  },
  confirmedCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0',
    color: theme.palette.text.primary,
  },
  confirmedIcon: {
    marginRight: '8px',
  },
  subtitle2: {
    color: theme.palette.text.primary,
  },
  caption: {
    color: theme.palette.text.hint,
  }
});

interface IProps extends WithStyles<typeof styles> {
  bookingData: any
  restaurantInfo: TRestaurant,
}

const ReserveInfo: React.FC<IProps> = ({
  classes,
  bookingData,
  restaurantInfo,
}) => {
  const { persons, date, fullName, phone } = bookingData;

  return (
    <Paper className={classes.confirmedPaper} elevation={3}>
      <Typography variant="subtitle2" className={classes.subtitle2}>
        { restaurantInfo.name }
      </Typography>
      <Typography variant="caption" className={classes.caption}>
        { restaurantInfo.address }
      </Typography>
      <Grid container className={classes.confirmedInfo}>
        <Grid item xs={7} sm={6} className={classes.confirmedCell}>
          <GroupIcon color="primary" fontSize="small" className={classes.confirmedIcon} />
          <Typography color="initial" variant="body2">
            { persons } {locales.t('common.reserveWidget.personSuffix')}
          </Typography>
        </Grid>
        <Grid item xs={5} sm={6} className={classes.confirmedCell}>
          <Typography color="initial" variant="body2">
            { fullName }
          </Typography>
        </Grid>
        <Grid item xs={7} sm={6} className={classes.confirmedCell}>
          <AccessTimeIcon color="primary" fontSize="small" className={classes.confirmedIcon} />
          <Typography variant="body2">
            { moment(date).format('DD MMMM, HH:mm') }
          </Typography>
        </Grid>
        <Grid item xs={5} sm={6} className={classes.confirmedCell}>
          <Typography variant="body2">
            { phone }
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(ReserveInfo);
