import React from 'react';
import { Provider } from 'react-redux';

import { ConnectedRouter as Router } from 'connected-react-router';
import 'typeface-roboto';
import history from '../../redux/history';
import store from '../../redux';
import ShowIfInitialized from "./ShowIfInitializedComp";
import ThemeComp from './ThemeComp';
import AppComp from './AppComp';

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <ShowIfInitialized>
        <ThemeComp>
          <AppComp />
        </ThemeComp>
      </ShowIfInitialized>
    </Router>
  </Provider>
);

export default App;
