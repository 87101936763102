import React, { PureComponent } from 'react';

import { IProps } from './ISmsCodeFormCont';
import SMSCodeForm from '../../components/SMSCodeForm';

class SmsCodeForm extends PureComponent<IProps, {}> {
  render() {
    const {
      resendRegistrationRequest,
      verifySmsCode,
      timeLeft,
      smsCodeError,
      phoneNumber,
    } = this.props;

    return (
      <SMSCodeForm
        phoneNumber={phoneNumber}
        handleSendSmsCode={resendRegistrationRequest}
        handleVerifySmsCode={verifySmsCode}
        timeLeftFromReq={timeLeft}
        errorMessage={smsCodeError}
      />
    );
  }
}

export default SmsCodeForm
