import { connect } from 'react-redux';

import { IAppStore } from '../../types/IAppStore';
import { StateProps } from './IErrorCont';

import ErrorCont from './ErrorCont';

function mapStateToProps(state: IAppStore): StateProps {
  const {
    widgetState,
    bookingError,
    restaurantInfo
  } = state.root;

  return {
    widgetState,
    bookingError,
    restaurantInfo,
  };
}

export default connect(mapStateToProps, {})(ErrorCont);
