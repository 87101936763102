import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button/Button';
import {
  createStyles, Theme, withStyles, WithStyles,
} from '@material-ui/core/styles';

import locales from '../../locales';
import PinCode from './PinCode';

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    padding: '40px 16px',
    maxWidth: '450px',
    margin: '0 auto',
  },
  phone: {
    marginBottom: '32px',
  },
  btnStyle: {
    marginTop: '64px',
    width: '100%',
    height: 52,
    fontSize: '18px',
  },
  timeLeft: {
    marginTop: '24px',
    opacity: '.7',
    color: theme.palette.text.hint,
  },
  errorMessage: {
    marginTop: '24px',
  },
});

interface IProps extends WithStyles<typeof styles> {
  timeLeftFromReq: number;
  phoneNumber?: string;
  errorMessage: string;
  handleSendSmsCode: () => void;
  handleVerifySmsCode: (code: string) => void;
}

interface IState {
  timeLeft: number;
  showResend: boolean;
}

class SMSCodeForm extends PureComponent<IProps, IState> {
  interval: number = 0;

  constructor(props: any) {
    super(props);
    this.state = {
      timeLeft: 99,
      showResend: false,
    };
  }

  componentDidMount() {
    this.startResendTimer();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  startResendTimer() {
    const { timeLeftFromReq } = this.props;

    this.setState({
      showResend: false,
      timeLeft: timeLeftFromReq,
    });

    this.interval = window.setInterval(this.tick, 1000);
  }

  onClickResendSms() {
    const { handleSendSmsCode } = this.props;
    handleSendSmsCode();
    this.startResendTimer();
  }

  onFullFilled(code: string) {
    const { handleVerifySmsCode } = this.props;
    handleVerifySmsCode(code);
  }

  tick = () => {
    const { timeLeft } = this.state;
    const { timeLeftFromReq } = this.props;

    if (timeLeft <= 0) {
      clearInterval(this.interval);
      this.setState({
        showResend: true,
        timeLeft: timeLeftFromReq,
      });
    } else {
      this.setState({
        timeLeft: timeLeft - 1,
      });
    }
  };

  render() {
    const { classes, phoneNumber, errorMessage } = this.props;
    const { showResend, timeLeft } = this.state;

    return (
      <div className={classes.container}>
        <Typography variant="subtitle1" align="center">
          {locales.t('common.reserveWidget.enterCode')}
        </Typography>
        <Typography variant="subtitle1" align="center" className={classes.phone}>
          <b>{ phoneNumber }</b>
        </Typography>
        <PinCode
          numberOnly
          onFullFilled={(code: string) => this.onFullFilled(code)}
        />
        {errorMessage && (
          <Typography variant="subtitle1" align="center" className={classes.errorMessage} color="error">
            { errorMessage }
          </Typography>
        )}
        {!showResend && !errorMessage && (
        <Typography variant="subtitle1" align="center" className={classes.timeLeft}>
          {locales.t('common.reserveWidget.resendMessage')}
          <b>{locales.t('common.reserveWidget.resendMessageSec', { seconds: timeLeft })}</b>
        </Typography>
        )}
        {(showResend || errorMessage) && (
          <Button
            className={classes.btnStyle}
            onClick={() => this.onClickResendSms()}
            variant="contained"
            color="primary"
            size="large"
          >
            {locales.t('common.reserveWidget.receiveSms')}
          </Button>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(SMSCodeForm);
