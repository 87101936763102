import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import classNames from 'classnames';

import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import locales from '../../locales';

const styles = createStyles({
  isError: {
    color: 'red',
  },
  fontSize: {
    fontSize: 14 // КОСТЫЛЬ ДЛЯ IOS
  }
});

interface IProps extends WrappedFieldProps, WithStyles<typeof styles> {
  maxCapacity: number
}

/** @description Селектор для выбора количества персон */
const PersonSelect: React.FC<IProps> = (
  { input, meta: { touched, error }, maxCapacity, classes }
) => {
  const isError = touched && !!error;
  const capacityList = new Array(maxCapacity)
    .fill('')
    .map((v, i) => i + 1);

  return (
    <FormControl fullWidth error={isError}>
      <InputLabel htmlFor="person">{ locales.t('common.reserveModal.persons') }</InputLabel>
      <Select
        {...input}
        className={classNames(classes.fontSize, { [classes.isError]: isError })}
      >
        {
          input.value === 0 && (
            <MenuItem value={0}>
              { locales.t('common.reserveModal.emptyPersons') }
            </MenuItem>
          )
        }
        {
          capacityList.map(person => (
            <MenuItem key={person} value={person}>
              { `${person} чел.` }
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(PersonSelect);
