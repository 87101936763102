export const GET_TABLES_COUNT_REQUEST = 'GET_TABLES_COUNT_REQUEST';
export const GET_TABLES_COUNT_SUCCESS = 'GET_TABLES_COUNT_SUCCESS';
export const GET_TABLES_COUNT_ERROR = 'GET_TABLES_COUNT_ERROR';

export const GET_MAX_PERSON_COUNT_REQUEST = 'GET_MAX_PERSON_COUNT_REQUEST';
export const GET_MAX_PERSON_COUNT_SUCCESS = 'GET_MAX_PERSON_COUNT_SUCCESS';
export const GET_MAX_PERSON_COUNT_ERROR = 'GET_MAX_PERSON_COUNT_ERROR';

export const RESTAURANT_SCHEDULE_REQUEST = 'RESTAURANT_SCHEDULE_REQUEST';
export const RESTAURANT_SCHEDULE_SUCCESS = 'RESTAURANT_SCHEDULE_SUCCESS';
