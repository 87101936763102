import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import ReserveWidget from '../RootWidgetCont';
import { Route } from 'react-router';

const AppComp: React.FC<{}> = () => (
  <Fragment>
    <CssBaseline />
    <Route path={'/:key'} component={ReserveWidget} />
  </Fragment>
);

export default AppComp;
