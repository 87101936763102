import { phoneCode } from '../config/constants';

function onlyNumbers(value: string) {
  return value.replace(/[^\d]/g, '');
}

/** @description Разбор номера на код и номер */
function getCodePhoneNumber(number: string): {
  code: string,
  number: string,
} {
  const onlyNums = onlyNumbers(number);
  const first = onlyNums.length >= 1 && onlyNums.substr(0, 1);
  const firstTwo = onlyNums.length >= 2 && onlyNums.substr(0, 2);
  const firstThree = onlyNums.length >= 3 && onlyNums.substr(0, 3);

  if (first) {
    if (firstThree) {
      if (`+${firstThree}` === phoneCode.uz.code) {
        return {
          code: phoneCode.uz.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.uz.code),
        };
      }

      if (`+${firstThree}` === phoneCode.am.code) {
        return {
          code: phoneCode.am.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.am.code),
        };
      }

      if (`+${firstThree}` === phoneCode.ge.code) {
        return {
          code: phoneCode.ge.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.ge.code),
        };
      }

      if (`+${firstThree}` === phoneCode.az.code) {
        return {
          code: phoneCode.az.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.az.code),
        };
      }

      if (`+${firstThree}` === phoneCode.ae.code) {
        return {
          code: phoneCode.ae.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.ae.code),
        };
      }

      if (`+${firstThree}` === phoneCode.by.code) {
        return {
          code: phoneCode.by.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.by.code),
        };
      }

      if (`+${firstThree}` === phoneCode.kg.code) {
        return {
          code: phoneCode.kg.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.kg.code),
        };
      }

      if (`+${firstThree}` === phoneCode.tm.code) {
        return {
          code: phoneCode.tm.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.tm.code),
        };
      }
    }

    if (firstTwo) {
      if (`+${firstTwo}` === phoneCode.ukr.code) {
        return {
          code: phoneCode.ukr.code,
          number: normalizePhone(onlyNums.slice(2), phoneCode.ukr.code),
        };
      }

      if (`+${firstTwo}` === phoneCode.est.code) {
        return {
          code: phoneCode.est.code,
          number: normalizePhone(onlyNums.slice(2), phoneCode.est.code),
        };
      }

      if (`+${firstTwo}` === phoneCode.kz.code) {
        return {
          code: phoneCode.kz.code,
          number: normalizePhone(onlyNums.slice(2), phoneCode.kz.code),
        };
      }
    }

    if (`+${first}` === phoneCode.ru.code) {
      return {
        code: phoneCode.ru.code,
        number: normalizePhone(onlyNums.slice(1), phoneCode.ru.code),
      };
    }

    if (`+${first}` === phoneCode.us.code) {
      return {
        code: phoneCode.us.code,
        number: normalizePhone(onlyNums.slice(1), phoneCode.us.code),
      };
    }
  }

  return {
    code: phoneCode.ru.code,
    number: '',
  };
}

/** @description Нормализация номера телефона в формат +7 999 9999999 или +380 99 999999 для полей ввода */
const normalizePhone = (value: string, code: string): string => {
  const onlyNums = onlyNumbers(value);
  if (onlyNums) {
    if (
      code === phoneCode.ru.code
      || code === phoneCode.us.code
    ) {
      if (onlyNums.length <= 3) {
        return onlyNums;
      }

      return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 10)}`;

    } else if (code === phoneCode.az.code) {
      // Если добавляют 0 перед кодом, например +994 055 225 2206
      const azPos = onlyNums[0] === '0' ? 3 : 2;
      const azLength = onlyNums[0] === '0' ? 10 : 9;

      if (onlyNums.length <= azPos) {
        return onlyNums;
      }

      return `${onlyNums.slice(0, azPos)} ${onlyNums.slice(azPos, azLength)}`;

    } else if (
      code === phoneCode.ukr.code
      || code === phoneCode.uz.code
      || code === phoneCode.am.code
      || code === phoneCode.ge.code
      || code === phoneCode.kz.code
      || code === phoneCode.ae.code
      || code === phoneCode.by.code
      || code === phoneCode.kg.code
      || code === phoneCode.tm.code
    ) {
      if (onlyNums.length <= 2) {
        return onlyNums;
      }

      return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 9)}`;

    } else if (code === phoneCode.est.code) {
      if (onlyNums.length <= 2) {
        return onlyNums;
      }

      return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 8)}`;
    }

    return value;
  }

  return '';
};

function getWidgetIdFromUrl() {
  const id = window.location.pathname.match(/\d+/g);

  if (id && id.length > 0) {
    return parseInt(id[0]);
  }

  return 0
}

function isFullScreen() {
  return document.body.clientWidth < 960;
}

function isSafari() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;
}

export {
  normalizePhone,
  getWidgetIdFromUrl,
  isFullScreen,
  isSafari,
  onlyNumbers,
  getCodePhoneNumber,
};
