import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import "moment/locale/ru";

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

import locales from '../../locales';
import { emptyTime } from '../../config/constants';

interface IProps extends WrappedFieldProps {}

// TODO Переписать логику отображения значений для "Сегодня" и "Выберете дату"
const DatePickerComp: React.FC<IProps> = (
  { input, meta: { touched, error }, }
) => {
  const renderDate = input.value !== emptyTime && moment(input.value).isSame(moment(), 'day')
    ? ''
    : input.value;

  const isError = touched && error;

  const FieldComp = (props: any) => {
    const {
      error, label, value, onClick, inputRef,
    } = props;
    const colorError = error ? { color: 'red' } : {};

    return (
      <TextField
        inputRef={inputRef}
        value={value}
        onClick={onClick}
        label={label}
        error={error}
        fullWidth
        InputProps={{
          style: colorError,
        }}
      />
    );
  };

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="ru">
      <DatePicker
        {...input}
        autoOk
        variant="inline"
        value={input.value === emptyTime ? null : renderDate}
        label={locales.t('common.reserveModal.selectDate')}
        disablePast
        format="DD.MM.YYYY"
        error={isError}
        invalidLabel={locales.t('common.rangeDatePicker.todayLabel')}
        emptyLabel={locales.t('common.rangeDatePicker.blank')}
        TextFieldComponent={FieldComp}
      />
    </MuiPickersUtilsProvider>
);
};

export default DatePickerComp;
