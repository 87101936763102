import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';
import { StateProps, DispatchProps } from './IRootWidget';

import {
  verifySmsCode,
  guestBooking,
  deleteBooking,
  sendRegistrationRequest,
} from '../../actions/rootWidget/RootWidgetActions';
import RootWidget from './RootWidget';

function mapStateToProps(state: IAppStore): StateProps {
  const {
    widgetState,
    restaurantInfo,
  } = state.root;

  return {
    widgetState,
    restaurantInfo,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): DispatchProps {
  return bindActionCreators({
    verifySmsCode,
    guestBooking,
    deleteBooking,
    sendRegistrationRequest,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RootWidget);
